
$gradient-colors: linear-gradient(to top, rgba(190, 13, 13, 0.795), rgba(255, 140, 0, 0.6));

#newPlayList {
  width: 100% !important;
  min-height: 100vh !important;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("../../assets/Image/Bulr1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .container-fluid {
    padding:0px 70px;
    .row {
      .mainCol {
        background-color: #ffffff3b !important;

        .mainColRow {
          margin: 0;
          width: 100;
          height: 73vh;
          overflow: auto;
          overflow-x: hidden;
          // border: 2px solid red;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px grey;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(255, 145, 0, 0.788);
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: linear-gradient(
              to top,
              darkred,
              rgb(255, 140, 0)
            );
            cursor: pointer;
          }
        }
      }
    }
  }
}
