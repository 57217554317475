input#placeholder::placeholder {
    color: white !important;
}

.beeksTags {
    border-radius: 20px;
    border: 1px solid lightgray;
}

.cursor-pointer {
    cursor: pointer;
}
