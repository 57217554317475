#playList {
  min-height: 100vh !important;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("../../assets/Image/Bulr1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .container-fluid {
    .row {
      padding: 20px 10px;
      .addTable {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0px;
      }
      .tables {
        background-color: transparent !important;
        max-height: 73vh;
        overflow: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 3px grey;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(255, 145, 0, 0.788);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: linear-gradient(to top, darkred, rgb(255, 140, 0));
          cursor: pointer;
        }

        tr {
          border-color: #ffffff99 !important;
          &:hover {
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        td,
        thead {
          background-color: transparent !important;
          color: white !important;
          padding: 12px 0;
        }
        .table {
          .maxWidth {
            width: 90%;
            max-width: 200px;
            @media (max-width: 1129px) {
              max-width: 170px;
            }
            @media (max-width: 996px) {
              max-width: 140px;
            }
            @media (max-width: 778px) {
              max-width: 120px;
            }
            .me-3 {
              margin-right: 10px !important;
            }
            span {
              abbr {
                text-decoration: none;
                cursor: alias;
                text-decoration-skip-ink: none;
              }
            }
          }
          .actionTd {
          }
        }
      }
    }
  }
  .audio1 {
    height: 90px;
    border-top: 1.3px solid #92818199;
    position: absolute;
    bottom: 0;
    transition: 5s;
    .rhap_time {
      color: white;
    }
    .rhap_repeat-button,
    .rhap_forward-button,
    .rhap_rewind-button {
      display: none;
    }
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.PlayerApp {
  width: 100%;
}

.audio {
  .PlayerApp {
    .rhap_container {
      box-shadow: none;
      background-color: transparent !important;
    }
    .rhap_progress-bar {
    }
    .rhap_progress-filled {
      background-image: linear-gradient(to right, red, rgb(255, 102, 0));
    }
  }
  .rhap_play-pause-button {
    path {
      fill: rgb(255, 102, 0);
    }
  }
}

#form-file-upload {
  height: 10rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;

  &.drag-active {
    background-color: #ffffff;
  }
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;

  &:hover {
    text-decoration-line: underline;
  }
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#File-upload {
  .custom-label {
    font-weight: bold;
    margin-bottom: 10px; // Adjust as needed
  }

  .custom-input {
    width: 100%;
    height: 150px;
    border: 1px dotted gray;
    background-color: white;
    padding: 10px; // Adjust as needed
    border-radius: 5px;
    margin-top: 5px; // Adjust as needed

    &::after {
      content: "Drag & Drop file";
      display: block;
      margin-top: 10px; // Adjust as needed
    }
  }
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
  z-index: 1000;
}

.loadingContent {
  text-align: center;
}

.spinner {
  color: yellow;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
